import { template as template_a42ef834843e434693401e63477ee3b3 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_a42ef834843e434693401e63477ee3b3(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
