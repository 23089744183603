import { template as template_48eda1c199e94b4489135276867f1418 } from "@ember/template-compiler";
const WelcomeHeader = template_48eda1c199e94b4489135276867f1418(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
