import { template as template_131d33d0f7f446aa99981f5687e19c62 } from "@ember/template-compiler";
const FKControlMenuContainer = template_131d33d0f7f446aa99981f5687e19c62(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
