import { template as template_4ccf4b1df151411fb6ff25405dfffeb8 } from "@ember/template-compiler";
const FKText = template_4ccf4b1df151411fb6ff25405dfffeb8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
