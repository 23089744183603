import { template as template_aa23fb668ffb4905a659ce0103cabfdc } from "@ember/template-compiler";
const FKLabel = template_aa23fb668ffb4905a659ce0103cabfdc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
